import React from 'react'
import { HeaderAndFooterLayout } from '../layouts/HeaderAndFooter'
import { css } from '../../styled-system/css'
import Pargh from '../components/photographie/Pargh'
import { HeadFC,  navigate, PageProps } from 'gatsby'
import { FeedBack, Styles } from '../types/photo'
import '../styles/phototxt.css'
import ImageDisp from '../components/photographie/ImageDisp'
import { Feedbacks_title } from '../components/photographie/Feedbacks'
import '../styles/photo_landing_cover.css'
import ButtonOrd from '../components/photographie/ButtonOrd'
import SEO from '../components/SEO'
import ImageTextSection from '../components/photographie/ImageTextSection'
import HeroSection from '../components/photographie/HeroSection'
import ResponsiveImage from './../components/photographie/ResponsiveImage';
import { useIsMobile } from './../utils/useIsMobile';

interface PhotoTemplateProps extends PageProps {
  pageContext: {
    id: string
    category: string
    titre: string
    type: string
    cover: string
    titles: string[]
    HtmlText: string[]
    images: string[]
    portraitImages?: string[]
    feedback: FeedBack[]
    name: string
    styles_name: string[]
    styles: Styles
    imageAlt: string
    SEO: {
      title: string
      description: string
      robotsContent: string
      image?: string   // Nouvelle propriété pour l'image Open Graph et Twitter Cards
    }
  }
}

const PhotoTemplate: React.FC<PhotoTemplateProps> = ({ pageContext }) => {
  const {
    id,
    category,
    cover,
    type,
    titre,
    titles,
    HtmlText,
    images,
    portraitImages,
    styles_name,
    styles,
    imageAlt,
  } = pageContext


  const getBackground = (cover: string) => {
    if (styles[styles_name[1]] && styles[styles_name[1]].backgroundColor) {
      return styles[styles_name[1]].backgroundColor
    }
    return 'transparent'
  }

  const handleClick = () => {
    localStorage.setItem('pageId', category)
    navigate('/photographie/galerie')
  }

  const isMobile = useIsMobile()
  
  return (
    <HeaderAndFooterLayout type={type}>
      <div
        className={css({
          overflow: 'hidden',
          height: !isMobile ? 'calc(100vh - 9rem)' : 'auto',
          width: '100%',
          position: 'relative',
          borderBottom: 'solid 8px #F2D98D',
        })}
      >
        <HeroSection titre={titre} id={id} />

        <div
          className={css({
            display: 'flex',
            flexDir: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            justifyContent: isMobile ? 'flex-start' : 'center',
            height: '100%',
            // backgroundColor: '#fff'
          })}
        >
          <div
            style={styles[styles_name[0]]}
            className={css({
              'position': 'relative',
              'zIndex': '9',
              'display': 'flex',
              'flexDirection': 'row',
              'flexWrap': 'nowrap',
              '@media (max-width: 768px)': {
                width: '100% !', 
              },
            })}
          >
            <ResponsiveImage
              className={css({
                maxHeight: '100%',
                objectFit: 'cover',
                height: '100vh',
                flexGrow: 1,
                '@media (max-width: 768px)': {
                  display: 'none'
                },
              })}
              imageName={cover}
              background={getBackground(cover)}
              imageAlt={imageAlt ?? ''}
            ></ResponsiveImage>
             {portraitImages && <ResponsiveImage
              className={css({
                maxHeight: '100%',
                height: '100vh',
                objectFit: 'cover',
                flexShrink: 2,
              })}
              imageName={portraitImages![0]}
              imageAlt={imageAlt ?? ''}
            ></ResponsiveImage>}
          </div>
          
        </div>
      </div>
      {/* ----------------------------------Presentation --------------------------------------*/}
      <div className={css({ width: '100%', margin: '50px auto 0' })}>
        <h2
          className={css({
            fontSize: '30px',
            width: '100%',
            textAlign: 'center',
          })}
        >
          {titles[0]}
        </h2>
        <div
          className={css({
            position: 'relative',
            display: 'flex',
            flexDir: 'row',
            margin: '2rem auto 2rem',
            width: '90%',
            gap: '0',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap: '20px',
              margin: '0 auto 2rem',
            },
          })}
        >
          <ResponsiveImage
            className={css({
              width: '60%',
              maxWidth: '600px',
              margin: '0 2rem 0 0',
              objectFit: 'cover',
              borderRadius: '4vh ',
              '@media (max-width: 768px)': {
                width: '100%',
                margin: '0 auto',
              },
            })}
            imageName="inna"
          ></ResponsiveImage>

          <Pargh
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '40%',
              textAlign: 'left',
              fontSize: '20px',
              '@media (max-width: 768px)': {
                width: '90%',
                margin: 'auto',
                textAlign: 'center',
                fontSize: '16px',
              },
            })}
            file={HtmlText[0]}
          ></Pargh>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDir: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '2rem',
          })}
        >
          <ButtonOrd />
        </div>
      </div>
      {/* ---------------------------------- disp --------------------------------------*/}
      <ImageDisp images={portraitImages ?? images}></ImageDisp>
      <div
        className={css({
          display: 'flex',
          flexDir: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '2rem',
          marginTop: '1.5rem',
        })}
      >
        <button
          className={`button3 ${css({
            border: 'black 1px solid',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          })}`}
          onClick={handleClick}
        >
          Voir plus de photos
        </button>
      </div>


      <ImageTextSection titles={titles} images={images} HtmlText={HtmlText} />




      <div
        className={css({
          margin: '50px auto 0',
          width: '50%',
          borderBottom: 'solid 2px #594A2E',
        })}
      ></div>
      {/*-------------------------feedBacks -----------------------------*/}
      <div className={css({ width: '100%', margin: '30px auto 0' })}>
        <Feedbacks_title id={id} title={titles[titles.length-1]} />
      </div>
    </HeaderAndFooterLayout>
  )
}

export default PhotoTemplate

export const Head: HeadFC = ({ pageContext }: PhotoTemplateProps) => {
  const { SEO: seoData, id } = pageContext
  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        robotsContent={seoData.robotsContent}
        image={seoData.image}
        url={`https://www.digituniverse.fr/photographie/${id}`}
        lang="fr"
        canonicalUrl={`https://www.digituniverse.fr/photographie/${id}`}
      />
    </>
  )
}
