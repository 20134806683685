import React from 'react'
import { css } from '../../../styled-system/css'
import { orderDefaultValues, useSetOrder } from '../../atoms/order.atom'
import { navigate } from 'gatsby'

export default function ButtonOrd({
  className,
  pkgId,
}: {
  className?: string
  pkgId?: string
}) {
  const setOrder = useSetOrder()

  function handleclick() {
    if (pkgId) {
      setOrder({
        ...orderDefaultValues,
        packageId: pkgId,
      })
    }

    navigate('/photographie/contact/')
  }

  if (process.env.GATSBY_NO_ORDER === 'true') {
    return null
  }

  return (
    <button
      className={`button3 ${className} ${css({
        border: 'black 1px solid',
        borderRadius: '0.5rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
      })}`}
      onClick={handleclick}
    >
      JE RESERVE
    </button>
  )
}
